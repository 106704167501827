.styled-table {
	border-collapse: collapse;
	margin: auto;
	font-size: 0.9em;
	font-family: sans-serif;
	/* width: calc(100vw - 200px); */
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
	background-color: #0061bd;
	color: #ffffff;
	text-align: center;
	border-radius: 20px;
}

.styled-table th,
.styled-table td {
	padding: 12px 15px;
	text-align: center;
}

.styled-table th:first-child {
	border-top-left-radius: 15px;
}

.styled-table th:last-child {
	border-top-right-radius: 15px;
}

.styled-table tbody tr {
	border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(odd) {
	background-color: #ffffff;
}

.styled-table tbody tr:nth-of-type(even) {
	background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
	border-bottom: 2px solid #0061bd;
}

.btn {
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	border-radius: 5px;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
}

.btn-edit {
	background-color: #0061bd;
}

.btn-delete {
	background-color: #f44336;
}

.btn-view {
	background-color: #e7e7e7;
	color: black;
}
